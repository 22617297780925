.pieces-container {
    /* columns: 3;
    column-fill: balance;
    align-content: space-between; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 15px;
}

@media (max-width: 1350px) {
    .pieces-container {
        columns: 2;
        max-width: 860px;
    }
  }

@media (max-width: 900px) {
    .pieces-container {
        columns: 1;
        width: 350px;
        margin-left: auto;
        margin-right: auto;
    }
  }