.App {
  /* background-color: #000000; */
  cursor: url("../public/eagle-64-ico.ico") 3 64, auto;
  background-image: url("../public/background-clouds.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Schoolbell", cursive;
  color: white;
}
.background-icon {
  z-index: 1;
  position: fixed;
  top: 64px;
  left: 0;
  height: 300px;
}
:visited {
  color: inherit;
  text-decoration: none;
}
.dark {
  color: black;
}
.light {
  color: white;
}
:hover {
  cursor: url("../public/eagle-64-ico.ico") 3 64, auto;
}