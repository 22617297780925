.contact-form {
    font-size: x-large;
    max-width: 500px;
    margin-top: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.02), 0 5px 12px -6px rgba(0, 0, 0, 0.1), 0 -12px 25px -19px rgba(0, 0, 0, 0.2);
    border: solid #000;
    border-color: black;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-left: auto;
    margin-right: auto;
    color: black;
  }
  
  .contact-form label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px;
    border-radius: 3px;
    margin-bottom: 15px;
    color: #000000;
    border: solid #000;
    border-color: black;
    border-width: 2px;
    font-family: arial;
  }
  
  .contact-form button {
    background-color: #414141;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 3px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
  }
  .contact-message {
    font-size: xx-large;
    margin-top: 15px;
    color: black;
    width: 100%;
    display: flex;
  }
  .contact-message-text {
    margin-left: 80px;
    margin-right: 80px;
  }
  @media screen and (max-width: 520px) {
    .contact-message-text {
      margin-left: 10px;
      margin-right: 10px;
    }
    .contact-form {
      margin-left: 10px;
      margin-right: 10px;
      
    }
  }