.footer {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  margin-top: auto;
  width: 100%;
  color: black;
  text-align: center;
  font-size: small;
  }
  .footer a {
    color: blue;
  }
  .social-links {
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }
  .social-link-img {
    height: 50px;
    margin: 10px;
  }
  