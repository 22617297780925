.shop-page-container {
    display: flex;
    flex-direction: column;
}
.shop-page-title {
    color: black;
    margin-left: 5vh;
    margin-top: 20px;
    font-size: 40px;
}
.empty-page {
    color: black;
    font-size: x-large;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
}
@media screen and (max-width: 600px) {
    .empty-page {
        margin-left: 30px;
        margin-right: 30px;
    }
}
