.piece-modal {
    display: none;
    z-index: 8;
    background-color: rgba(255, 255, 255, 0);
    position: fixed;
    /* height: 80vh;
    width: 80vw; */
    /* top: -15px; */
    transition: height 0.2s ease-out, width 0.2s ease-out;
    color: black
}
.modal-content {
    border: solid #000;
    border-color: black;
    border-width: 2px;
    display: flex;
    flex-direction: row;
    height: 80%;
    width: 80%;
    margin: auto;
}
.piece-modal-open{
    display: flex;
    flex-direction: row;
    opacity: 0;
}

.piece-modal-animation {
    opacity: 1;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    transition: opacity 0.2s ease-out, top 0.2s ease-out, left 0.2s ease-out,;
}

.piece-modal-image-container {
    height: 100%;
    width: 40vw;
    background-color: rgba(0, 0, 0, 0.295);
    backdrop-filter: blur(20px);
    display: flex;
}
.piece-modal-current-image {
    margin: auto;
    border: 2px solid black;
    max-width: 70%;
}
.image-scroll {
    padding: 4px;
    border: 2px solid black;
    background-color: white;
    font-size: xx-large;
    margin: auto;
}
.piece-modal-info {
    display: flex;
    padding: 3vw;
    flex-direction: column;
    height: 100%;
    width: 50vw;
    background-color: rgba(255, 255, 255, 0.349);
    backdrop-filter: blur(20px);
}
.piece-modal-info * {
    margin-left: 10px;
    margin-bottom: auto;
}
.title {
    font-size: xx-large;
}
.description {
    font-size: x-large;
}
.measurements {
    font-size: x-large;
}
.buy-button {
    font-weight: 900;
    max-width: 300px;
    height: 70px;
    margin-left: auto;
    margin-right: auto;
    font-size: x-large;
    border: solid #000;
    border-color: black;
    border-width: 2px;
    background-color: white;
    border-radius: 2px;
    display: flex;
    color: gray;
    border-color: gray;
}
.buy-button span{
    margin: auto;
}
.modal-close-button {
    padding: 10px;
    margin-top: 10vh;
    margin-right: 10vw;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 7;
}
.modal-close-button span{
    font-size: xx-large;
}

@media screen and (max-width: 1000px) {
    .piece-modal-open {
        display: flex;
        
    }
    .modal-close-button {
        margin-top: 10vh;
    }
    .modal-content {
        display: flex;
        flex-direction: column;
        height: 70vh;
        margin-top: 10vh;
    }
    .piece-modal-image-container {
        height: 400px;
        width: 100%;
    }
    .piece-modal-info {
        width: auto;
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
    }
    .piece-modal-info .description, .piece-modal-info .measurements{
        font-size: medium;
        margin-top: 10px;
        margin-bottom: 20px;
    }
}

@media (max-height: 800px) {
    .piece-modal-image-container {
        height: 300px;
    }
}
