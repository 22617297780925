@import url('https://fonts.googleapis.com/css2?family=Schoolbell&display=swap');

.header {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 64px;
    background-color: #c8e4ff00;
    backdrop-filter: blur(10px);
    /* border-bottom: 2px solid black; */
    /* box-shadow: 0 0 5px rgb(97, 97, 97), 0 5px 12px -6px rgb(97, 97, 97), 0 -12px 25px -19px rgb(97, 97, 97); */
  }
  .logo-word {
    margin-left: 1vw;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 40px;
    cursor: default;
  }
  .navigation-menu{
    margin-right: 0;
    margin-left: auto;
    height: 100%;
    display: flex;
  }
  .dropdown-navigation {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: xxx-large;
    transition: all 0.2s linear;
    backdrop-filter: blur(10px);
    background-color: #0000006c;
  }
  .dropdown-navigation-item {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: 100%;
    height: 17vh;
    border: 0;
    padding: auto;
    --webkit-appearance: none !important;
    -moz-appearance: none;
    appearance: none;
    color: rgb(255, 255, 255);
  }
  .shop-dropdown {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: fixed;
    font-size: x-large;
    left: 0;
    transition: all 0.4s linear;
    backdrop-filter: blur(10px);
    background-color: #c8e4ff00;
    /* border-bottom: 2px solid black; */
    /* box-shadow: 0 0 5px rgb(97, 97, 97), 0 5px 12px -6px rgb(97, 97, 97), 0 -12px 25px -19px rgb(97, 97, 97); */
  }
  .shop-dropdown-container {
    margin-left: auto;
    margin-right: 0;
  }
  .shop-dropdown-img {
    height: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .menu {
    z-index: 9;
    display: none;
  }
  .shop-navigation-item {
    height: 180px;
    width: 180px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: inherit;
    outline: none;
    box-shadow: none;
    background: none;
    color: white;
    border: none;
    /* border-left: 2px solid black; */
    padding-left: 10px;
    padding-right: 0px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin-right: 15px;
  }
  .toggle_shop_on {
    display: flex;
    top: 64px;
  }
  .toggle_on {
    display: flex;
    top: 64px;
  }
  .shop-dropdown .toggle_on{
    background-color: aliceblue;
    display: flex;
    float: right;
  }
  .unlock-header {
    height: max-content;
    display: flex;
  }
  .navigation-dropdown{
    height: 100%;
    display: none;
    background-color: inherit;
    border: none;
    outline: none;
    box-shadow: none;
    margin-right: 1%;
    margin-left: auto;
    color: #ffffff;
  }
  .navigation-dropdown-icon {
    height: 100%;
  }
  .nav-btn-background {
    height: 100%;
  }
  .navigation-item {
    text-decoration: none;
    padding-right: 1vw;
    padding-left: 1vw;
    color:#ffffff;
    height: 100%;
    font-size: large;
    font-weight: bolder;
    background-color: inherit;
    background: #00000000;
    border: none;
    outline: none;
    box-shadow: none;
  }
  .navigation-item:hover {
    color: blue;
    cursor: pointer;
    /* background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: rainbow_animation 4s ease-in-out infinite;
    background-size: 400% 100%;
    transition: all 0.5s; */
  }
  .shop-nav-button:hover {
    .shop-dropdown-menu {
      display: block;
      height: auto;
      font-size: x-large;
    }
  }
  .shop-dropdown-item-name {
    background-color: rgba(0, 0, 0, 0.247);
    border-radius: 5px;
    padding-right: 5px;
    padding-left: 5px;
  }
    
  
  @keyframes rainbow_animation {
    0%,100% {
        background-position: 0 0;
    }
    50% {
        background-position: 100% 0;
    }
  }
  .navigation-item:visited {
    color: inherit;
  }

  @media screen and (max-width: 600px) {
    .navigation-menu {
      display: none;
    }
    .navigation-dropdown {
      display: block;
    }
    .logo-word {
      font-size: 26px;
    }
    .shop-dropdown {
      background-color: #c8e4ff00;
      height: 100vh;
    }
    .shop-dropdown-img {
      height: 70%;
    }
    .shop-dropdown-container {
      margin-left: 0;
      width: 100%;
    }
    .shop-navigation-item {
      height: 160px;
      margin-left: auto;
      margin-right: auto;
      width: auto;
      display: flex;
      margin: auto;
      display: flex;
      border: none;
      font-size: xx-large;
    }
    .shop-navigation-item *{
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 0;
      margin-right: 10px;
    }
  }