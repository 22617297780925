.piece-container {
    position: relative;
    display: flex ;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
    background-color: rgb(255, 255, 255);
    /* backdrop-filter: blur(10px); */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.02), 0 5px 12px -6px rgba(0, 0, 0, 0.1), 0 -12px 25px -19px rgba(0, 0, 0, 0.2);
    border: solid #000;
    border-color: black;
    border-width: 2px;
    width: 400px;
    height: 400px;
    text-decoration: none;
    margin-bottom: 5%;
    color: rgb(0, 0, 0);
    -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
    page-break-inside: avoid; /* Firefox */
    break-inside: avoid;   
}
.piece-info {
    height: 50px;
    display: flex;
    margin-top: auto;
    margin-bottom: 0;
    font-size: x-large;
    width: 100%;
}
.piece-title {
    text-decoration: none;
    align-content: center;
    padding-left: 1%;
    padding-right: 1%;
}
.piece-price {
    margin-right: 10px;
    margin-left: auto;
}
.piece-container .img-container {
    margin: 0;
    height: 350px;
    padding-top: 10px;
    object-fit: contain;
    display: flex;
}
.piece-container .img-container img { 
    height: 100%;
    width: 100%;
    object-fit: contain;
    margin-top: auto;
    margin-bottom: auto;
}
.grayed-out {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%); 
}
.red {
    color: red;
    font-weight: 700;
}
.piece-container:hover {
    transform: scale(1.1);
    transition: transform 0.5s;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.02), 0 5px 12px -6px rgba(0, 0, 0, 0.1), 0 -12px 25px -19px rgba(0, 0, 0, 0.2);
}
a {
    padding: 0%;
    text-decoration: none;
}
@media screen and (max-width: 600px) {
    .piece-container:hover {
        transform: revert;
        box-shadow: revert;
    }
}
@media (max-width: 1350px) {
    .piece-container {
        margin-left: 15px;
        margin-right: 15px;
    }
  }

@media (max-width: 900px) {
    .piece-container {
        margin-left: auto;
        margin-right: auto;
    }
  }

/* .image-carousel {
    height: 400px;
    margin-right: auto;
    margin-left: auto;
}

.image-carousel img {
    height:300px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.image-carousel:hover img {
    opacity: 1;
}

.image-carousel img:nth-child(1) {
    opacity: 1;
} */