.about-section {
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px;
    width: auto;
    margin-left: 15px;
    margin-right: 15px;
    padding: 10px;
    color: black;
    height: auto;
    font-size: 30px;
    background-color: #ffffff;
    border: 2px solid black;
}
.about-section span{
    margin: 10px;  
}
.about-section img {
    margin-left: 10px;
    margin-right: 10px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    /* border: solid #000;
    border-color: black;
    border-width: 1px; */
    
}
.about-section a {
    color: blue; 
}
.about-subsection {
    width: 100%;
    display: block;
}
@media screen and (max-width: 600px) {
    .about-section {
        display: inline;
        margin-left: auto;
        margin-right: auto;
        font-size: 20px;
    }
  }

@media (max-width: 1200px) {
    .about-section {
        display: block;
    }
    .about-section img{
        margin-left: auto;
        margin-right: auto;
    }
  }